import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import { S } from './TileTitleWithPrice';

type TileTitlePriceProps = {
  discountedPrice: string | undefined;
  initialPrice: string | undefined;
  originalPriceCopy: string | undefined;
  discountedColor?: string;
};

export const TileTitlePrice = ({
  discountedPrice,
  initialPrice,
  originalPriceCopy,
  discountedColor,
}: TileTitlePriceProps) => {
  const {
    configuration: { lowestPriceDisplay, showLowestPriceText },
  } = useStaticContext();

  const isOnlyLowestPrice = lowestPriceDisplay === 'ONLY_LOWEST_PRICE' && showLowestPriceText;

  return (
    <>
      <S.PriceWrapper>
        {discountedPrice !== initialPrice && initialPrice ? (
          <S.PriceLowest30Days data-testid="product-tile-initial-price">
            {isOnlyLowestPrice ? (
              ''
            ) : (
              <>
                {originalPriceCopy} {initialPrice.replace(' ', '')}
              </>
            )}
          </S.PriceLowest30Days>
        ) : (
          <></>
        )}
        {discountedPrice && discountedPrice !== initialPrice ? (
          <S.DiscountedPrice $color={discountedColor} data-testid="product-tile-price">
            {discountedPrice}
          </S.DiscountedPrice>
        ) : (
          <S.InitialPrice data-testid="product-tile-price">{initialPrice}</S.InitialPrice>
        )}
      </S.PriceWrapper>
    </>
  );
};
